import React, { useEffect } from "react";
import { useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment';
import "./AppointmentManagement.css"
import { deleteRequestWithToken, getRequestWithToken, postRequestWithToken } from "../http-requests/HTTPRequests.js";
import { Link } from "react-router-dom";
import translations from "../translations.json"


/**
 * This function returns the ExpCalendar.
 * It is used to display a calendar.
 */
export default function AppointmentManagement({ username, connectedId, facilityName, duration, ecs }) {

    //Sets the language stored in the sessionStorage
    const currentTranslations = translations[sessionStorage.getItem("language")] || {}

    const [showEventInfo, setShowEventInfo] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    function handleSelectEvent (event){
        setSelectedEvent(event);
        setShowEventInfo(true);
    }
    const [selectedSlot, setSelectedSlot] = useState(false)
    const [currentSlotInfo, setCurrentSlotInfo] = useState(null);
    const [newView, setNewView] = useState("month")
    const [events, setEvents] = useState([]);
    const [title, setTitle] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [description, setDescription] = useState("");
    

    /**
     * This hook gets called when the component is mounted.
     * It calls the getAppointments-function and sets showEventInfo to false.
     */
    useEffect(() => {
        getAppointments();
        // setShowEventInfo(false);
    }, []);

    /**
     * This function calls the getCalendarExperimentFacility-function if a facilityName is set. 
     * Otherwise it calls the getCalendarUser-function.
     */
    function getAppointments() {
        if (facilityName) {
            getCalendarExperimentFacility()
        } else {
            getCalendarUser()
        }
    }

    /**
     * This function calls the getRequestWithToken, to get the appointements stored for the passed facilityName.
     * If the Request was successful, this function sets the events stored in the response.
     */
    async function getCalendarExperimentFacility() {
        getRequestWithToken(ecs, `/api/scheduling/appointments/getallappointmentsbookedbyconnectedfacilityname/connectedfacilityname?connectedfacilityname=${facilityName}`, sessionStorage.getItem("token"))
            .then(
                response => {
                    if (Array.isArray(response.data)) {
                        setEvents(response.data.map(event => ({
                            title: event.appointmentName,
                            start: new Date(event.startDateTime),
                            end: new Date(event.endDateTime),
                            description: event.appointmentDescription,
                            bookedByUser: event.bookedByUser,
                            appointmentId: event.appointmentId,
                            facilityId: event.connectedFacility.connectedFacilityId,
                            bookedByUserRole: event.bookedByUserRole,
                            connectedFacilityName: event.connectedFacility.connectedFacilityName
                        })))
                    }
                }
            ).catch(error => {
                console.log(error)
            })
    }

    /**
         * This function calls the getRequestWithToken, to get the appointements stored for the user.
         * If the Request was successful, this function sets the events stored in the response.
         */
    async function getCalendarUser() {
        getRequestWithToken("", `/api/scheduling/appointments/getallappointmentsbookedbyusername/bookedByUserName?bookedByUserName=${username}`, sessionStorage.getItem("token"))
            .then(
                response => {
                    console.log(response)
                    if (Array.isArray(response.data)) {
                        setEvents(response.data.map(event => ({
                            title: event.appointmentName,
                            start: new Date(event.startDateTime),
                            end: new Date(event.endDateTime),
                            description: event.appointmentDescription,
                            bookedByUser: event.bookedByUser,
                            appointmentId: event.appointmentId,
                            facilityId: event.connectedFacility.connectedFacilityId, 
                            bookedByUserRole: event.bookedByUserRole,
                            connectedFacilityName: event.connectedFacility.connectedFacilityName
                        })))
                    }
                }
            ).catch(error => {
                console.log(error)
            })
    }

    /**
     * This function checks if the event is at the current time
     */
    function isEventInProgress(event) {
        const now = new Date();
        return event.start <= now && event.end >= now;
    }

    /**
     * This function sets the color of the event. 
     * If the bookedByUserRole equals "ADMIN", the color of the event gets set to red. Otherwise the color gets set to green 
     */
    function eventStyleGetter (event) {
        if (event.bookedByUserRole === 'ADMIN' || event.bookedByUserRole === "ROOT_ADMIN") {
            return {
                style: {
                    backgroundColor: 'red'
                }
            };
        }
        else {
            return {
                style: {
                    backgroundColor: 'green'
                }
            };
        }
    };

    useEffect(() => {
        console.log(selectedEvent)
    }, [selectedEvent])

    /**
     * This function adds a Event by calling the postRequestWithToken-function.
     * It passes the parameters appointmentName, bookedByUser, bookedByUserRole, startDateTime, endDateTime, appointmentDescription and connectedFacility to add the event.
     * If the request was successful, the selectedSlot gets set to null and the getAppointments-function gets called.
     */
    async function addEvent(title, start, end, description) {
        document.getElementById("calendarLabel").innerHTML = "Event wird hinzugefügt..."
        postRequestWithToken(ecs, "/api/scheduling/appointments",
            { appointmentName: title, bookedByUser: sessionStorage.getItem("user"), bookedByUserRole: sessionStorage.getItem("role"),startDateTime: start, endDateTime: end, appointmentDescription: description, connectedFacility: { connectedFacilityId: connectedId} },
            sessionStorage.getItem("token"))
            .then(response => {
                if (response.status === 201) {
                    setSelectedSlot(null)
                    document.getElementById("calendarLabel").innerHTML = currentTranslations.addedEvent
                    getAppointments();
                } else {
                    document.getElementById("calendarLabel").innerHTML = currentTranslations.addEventFailed
                }
            }
            ).catch(error => {
                document.getElementById("calendarLabel").innerHTML = currentTranslations.addEventFailed
                console.log(error)
            })
    }


    /**
     * This function deletes a chosen event by the id.
     * It calls the deleteRequestWithToken-function. 
     * If the request was successful, the getAppointments-function gets called and the selectedEvent gets set to null.
     */
    async function deleteEvent(id) {
        deleteRequestWithToken(ecs, `/api/scheduling/appointments/deleteappointment/appointmentid?appointmentid=${id}`, sessionStorage.getItem("token"))
            .then(() => {
                getAppointments();
                setSelectedEvent(null)
                document.getElementById("calendarLabel").innerHTML = currentTranslations.deletedEvent
            }).catch(error => {
                console.log(error);
            })
    }


    
    /**
     * This function adds passed Minutes to a passed date.
     */ 
    function addMinutes(date, minutes) {
        return new Date(date.getTime() + minutes * 6000);
    }

    /**
     * Returns the ExpCalendar-form.
     */
    return (<>
        <div className="center">
            <div className="calendar">
                <Calendar localizer={localizer} events={events} defaultDate={new Date()} views={['month', 'week', 'day']} selectable={newView === "day" && facilityName ? true : false} eventPropGetter={eventStyleGetter} className={newView === "day" && facilityName ? "selectable calendar" : "calendar"} onView={(newView) => setNewView(newView)}
                    onSelectSlot={(slotInfo) => {
                        setSelectedSlot(true);
                        setCurrentSlotInfo(slotInfo);
                        setTitle("");
                        setStart(slotInfo.start.toISOString());
                        setEnd(slotInfo.end.toISOString());
                    }}
                    onSelectEvent={handleSelectEvent}
                />
                {selectedSlot && (
                    <>
                        <form onSubmit={(e) => { e.preventDefault(); addEvent(title, start, end, description); setSelectedSlot(false); }}>
                            <input
                                type="text"
                                placeholder="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <br />
                            <label>Start: {new Date(start).toLocaleString()}</label>
                            <br />
                            <label>End: {new Date(end).toLocaleString()}</label>
                            <br />
                            <textarea
                                placeholder="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <br />
                            <button type="submit">{currentTranslations.save}</button>
                        </form>

                    </>
                )}
                {selectedEvent && (
                    <div>
                        <p>{selectedEvent.title}</p>
                        <p>Start: {selectedEvent.start.toLocaleString()}</p>
                        <p>End: {selectedEvent.end.toLocaleString()}</p>
                        {isEventInProgress(selectedEvent) && sessionStorage.getItem("user") === selectedEvent.bookedByUser && (
                            <Link to={`/experiment/${selectedEvent.facilityId}/${selectedEvent.appointmentId}/${selectedEvent.connectedFacilityName}`}>{currentTranslations.startSession}</Link>

                        )}
                        {(sessionStorage.getItem("role") === "ADMIN" || sessionStorage.getItem("role") === "ROOT_ADMIN" || selectedEvent.bookedByUser === sessionStorage.getItem("user")) &&
                        <button onClick={() => deleteEvent(selectedEvent.appointmentId)}>Delete</button>}
                    </div>
                )}
            </div>
            <label id="calendarLabel"></label>
        </div>
    </>
    )
}

//Needed to display calendar
const localizer = momentLocalizer(moment)